"use client";

import dynamic from "next/dynamic";
import NextError from "next/error";

import { env } from "@tweetdm/env/client";

const SentryCaptureError = dynamic(
  () => import("@/components/sentry-capture-error"),
  { ssr: false },
);

export default function GlobalError({
  error,
}: {
  error: Error & { digest?: string };
}) {
  return (
    <html lang="en">
      <body>
        {env.NEXT_PUBLIC_STAGE !== "development" && (
          <SentryCaptureError error={error} />
        )}
        {/* `NextError` is the default Next.js error page component. Its type
        definition requires a `statusCode` prop. However, since the App Router
        does not expose status codes for errors, we simply pass 0 to render a
        generic error message. */}
        <NextError statusCode={0} />
      </body>
    </html>
  );
}
